<template>
  <v-dialog
    v-model="showModal"
  >
    <v-card class="card-container">
      <br>
      <h2 class="modal-title-style">
        <v-icon class="modal-icon-style">
          mdi-qrcode
        </v-icon>
        Preview QR Code
        <v-icon
          class="close-button"
          @click="showModal = false"
        >
          mdi-window-close
        </v-icon>
      </h2>
      <br>
      <div class="image-container">
        <img
          v-if="isContentImgOrGif(contentDetails.content.content_type)"
          id="image"
          ref="mainImage"
          :src="getFilePath(contentDetails.content.file_path)"
          class="main-image"
          @load="onImageLoad"
        >
        <video
          v-if="!isContentImgOrGif(contentDetails.content.content_type)"
          id="video"
          ref="mainVideo"
          class="main-image"
          loop
          autoplay
          @loadedmetadata="onVideoLoad"
        >
          <source
            :src="getFilePath(contentDetails.content.file_path)"
            type="video/mp4"
          >
        </video>
        <div
          ref="qrCode"
          class="qr-code"
          :style="{ top: qrCodePosition.y + 'px', left: qrCodePosition.x + 'px' }"
        >
          <img
            :src="qrCodeImage"
            alt="QR Code"
          >
        </div>
      </div>
      <br>
    </v-card>
    <centre-spinner :loading="loading" />
  </v-dialog>
</template>

<script>
import qrcode from 'qrcode';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import { cdnUrl } from 'src/app-globals';

export default {
  name: 'PreviewContentWithQrCode',
  components: {
    'centre-spinner': spinner,
  },
  props: {
    contentDetails: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      showModal: true,
      qrCodeImage: '',
      qrCodePosition: { x: 0, y: 0 },
      loading: false,
      imageOriginalWidth: 0,
      imageOriginalHeight: 0,
    };
  },
  watch: {
    showModal (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  async mounted () {
    await this.generateQRCode();
  },
  methods: {
    getFilePath (fileName) {
      return `${cdnUrl}/${fileName}`;
    },
    isContentImgOrGif (contentType) {
      if (contentType === 'image' || contentType === 'gif') {
        return true;
      }
      return false;
    },
    async generateQRCode () {
      this.loading = true;
      try {
        this.qrCodeImage = await qrcode.toDataURL(this.contentDetails.qr_code_banner.qr_code_redirect_uri);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    onImageLoad () {
      this.imageOriginalWidth = this.$refs.mainImage.naturalWidth;
      this.imageOriginalHeight = this.$refs.mainImage.naturalHeight;
      this.loadQrCodePosition();
    },
    onVideoLoad () {
      this.imageOriginalWidth = this.$refs.mainVideo.videoWidth;
      this.imageOriginalHeight = this.$refs.mainVideo.videoHeight;
      this.loadQrCodePosition();
    },
    loadQrCodePosition () {
      if (this.contentDetails.qr_code_banner) {
        this.qrCodePosition.x = this.contentDetails.qr_code_banner.qr_code_x_position;
        this.qrCodePosition.y = this.contentDetails.qr_code_banner.qr_code_y_position;

        this.adjustPositionForScreenSize();
      } else {
        this.qrCodePosition.x = 10; // Default position
        this.qrCodePosition.y = 10; // Default position
      }
    },
    adjustPositionForScreenSize () {
      if (this.isContentImgOrGif(this.contentDetails.content.content_type) === true) {
        if (this.$refs.mainImage) {
        // Get current dimensions of the image on the screen
          const currentWidth = this.$refs.mainImage.clientWidth;
          const currentHeight = this.$refs.mainImage.clientHeight;

          // Adjust position based on current screen size compared to original image size
          this.qrCodePosition.x = (this.qrCodePosition.x / this.imageOriginalWidth) * currentWidth;
          this.qrCodePosition.y = (this.qrCodePosition.y / this.imageOriginalHeight) * currentHeight;
        }
      } else {
        if (this.$refs.mainVideo) {
        // Get current dimensions of the image on the screen
          const currentWidth = this.$refs.mainVideo.clientWidth;
          const currentHeight = this.$refs.mainVideo.clientHeight;

          // Adjust position based on current screen size compared to original image size
          this.qrCodePosition.x = (this.qrCodePosition.x / this.imageOriginalWidth) * currentWidth;
          this.qrCodePosition.y = (this.qrCodePosition.y / this.imageOriginalHeight) * currentHeight;
        }
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.qr-code {
  position: absolute;
  z-index: 10;
  cursor: move;
}

.qr-code img {
  width: 100px; /* Default size, adjust as needed */
  height: 100px; /* Default size, adjust as needed */
}

@media (max-width: 767px) {
  .qr-code img {
    width: 50px; /* Adjust size for smaller screens */
    height: 50px; /* Adjust size for smaller screens */
  }
}

.modal-title-style {
  color: #37474F;
  margin-left: 30px;
}

.modal-icon-style {
  color: #37474F;
  font-size: 45px;
}

.close-button {
  float: right;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;
  color: #37474F;
}
</style>
