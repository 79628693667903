<template>
  <v-dialog
    v-model="showModal"
    max-width="1200"
    persistent
  >
    <v-card
      class="base-card"
      outlined
      color="#ECEFF1"
    >
      <v-container
        id="regular-tables"
        fluid
      >
        <br>
        <h2 class="title-style">
          <v-icon
            class="icon-style"
            left
          >
            mdi-file-document-check-outline
          </v-icon>Users Info
          <v-icon
            class="close-button"
            color="#37474F"
            @click="showModal = false"
          >
            mdi-close
          </v-icon>
        </h2>
        <br>
        <div>
          <p class="title-header">
            Banner Name:- <font
              class="title-name"
            >
              {{ bannerDetails.content.original_file_name }}
            </font><br>
            <span v-if="isDetailsFetched">
              <v-chip>
                <v-icon
                  small
                  color="#37474F"
                >
                  mdi-qrcode-scan
                </v-icon>
                <font class="count-style">
                  Scanned Users: {{ qrCodeScannedUsersCount.count }} <font class="separator">
                    |
                  </font>
                </font>
                <v-icon
                  small
                  color="#37474F"
                >
                  mdi-cloud-arrow-right-outline
                </v-icon>
                <font class="count-style">
                  Redirect URL: {{ bannerDetails.qr_code_banner.qr_code_redirect_uri | formatUrl }}
                </font>
              </v-chip>
            </span>
          </p>
        </div>
        <v-card
          shaped
          color="#CFD8DC"
        >
          <br>
          <br>
          <v-card-title>
            <v-row>
              <v-col
                cols="12"
                lg="4"
                md="5"
                sm="12"
              >
                <v-text-field
                  v-model="search"
                  label="Search"
                  class="search-style"
                  append-icon="mdi-magnify"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="qrCodeScannedUsers"
            :search="search"
            disable-sort
            class="elevation-1"
            mobile-breakpoint="100"
            light
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.first_name }} {{ item.last_name }}</td>
                <td>
                  <font v-if="item.email !== null">
                    {{ item.email }}
                  </font>
                  <font v-else>
                    N/A
                  </font>
                </td>
                <td>{{ item.phone_number }}</td>
                <td>{{ item.created_at | convertToLocal }}</td>
              </tr>
            </template>
          </v-data-table>
          <br>
        </v-card>
        <br>
      </v-container>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import global from 'src/mixins/global';

  export default {
    name: 'QrCodeScannedUsers',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      formatUrl (url) {
        return url.split('redirectUrl=').pop();
      },
    },
    mixins: [global],
    props: {
      bannerDetails: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        showModal: true,
        search: null,
        headers: [
          { text: 'Name', align: 'left', value: 'first_name' },
          { text: 'Email', value: 'email' },
          { text: 'Phone', value: 'phone_number' },
          { text: 'Added On', value: 'created-at' },
        ],
        loading: false,
      };
    },
    computed: {
      qrCodeScannedUsers () {
        return this.$store.getters['qrcodebanners/getQrCodeScannedUsers'];
      },
      qrCodeScannedUsersCount () {
        return this.$store.getters['qrcodebanners/getQrCodeScannedUsersCount'];
      },
      isDetailsFetched () {
        if (Object.keys(this.qrCodeScannedUsersCount).length > 0) {
          return true;
        }
        return false;
      },
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    async mounted () {
      this.permissionCheck('get-qr-code-scanned-users-count') && await this.getQrCodeScannedUsersCount();
      this.permissionCheck('get-qr-code-scanned-users') && await this.getQrCodeScannedUsers();
    },
    methods: {
      async getQrCodeScannedUsers () {
        this.loading = true;
        await this.$store.dispatch('qrcodebanners/fetchQrCodeScannedUsers', {
          bannerId: this.bannerDetails.id,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      async getQrCodeScannedUsersCount () {
        this.loading = true;
        await this.$store.dispatch('qrcodebanners/fetchQrCodeScannedUsersCount', {
          bannerId: this.bannerDetails.id,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
    },
  };
</script>

<style scoped>
.base-card {
  padding-right: 30px;
  padding-left: 30px;
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.close-button {
  float: right;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;
  color: #37474F;
}
.title-header {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bolder;
  margin-left: 5px;
  color: #37474F;
  font-size: 18px;
}
.title-name {
  color: #FF3700;
}
@media (max-width: 960px) {
  .title-header {
    margin-top: 30px;
  }
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.search-style {
  color: #37474F;
  font-weight: 600;
}
.count-style {
  font-weight: 600;
  margin-left: 6px;
  font-size: 14px;
}
.separator {
  color: #FF3700;
  margin-left: 6px;
  margin-right: 8px;
}
</style>
