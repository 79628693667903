<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-qrcode
      </v-icon> QR Code Banners
    </h2>
    <br>
    <v-alert
      v-if="!permissionCheck('get-qr-code-enabled-banners')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="authorization-alert"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="qrCodeBanners"
        :search="search"
        class="elevation-1"
        disable-sort
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <div v-if="isContentImgOrGif(item.content.content_type)">
                <v-img
                  width="100"
                  height="100"
                  :src="getFilePath(item.content.file_path)"
                  contain
                  @click="popUpImage(item)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FF3700"
                      />
                    </v-row>
                  </template>
                </v-img>
              </div>
              <div v-else>
                <video
                  width="100"
                  height="100"
                  contain
                  @click="popUpImage(item)"
                >
                  <source
                    :src="getFilePath(item.content.file_path)"
                    type="video/mp4"
                  >
                </video>
              </div>
            </td>
            <td>
              <font
                :color="getColor(item.qr_code_enabled)"
                class="font-my-style"
              >
                {{ item.qr_code_enabled | qrStatusUpdate }}
              </font>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.content.original_file_name }}</td>
            <td>{{ item.start | convertToLocal }}</td>
            <td>
              <font v-if="item.end !== null">
                {{ item.end | convertToLocal }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font
                :color="getColor(item.active)"
                class="font-my-style"
              >
                {{ item.active | statusUpdate }}
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                color="#37474F"
                x-small
                icon
                @click="viewQrCodeScannedUsers(item)"
              >
                <v-icon>mdi-qrcode-scan</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <qr-code-scanned-users
      v-if="showQrCodeScannedUsers"
      :banner-details="bannerDetails"
      @closed="showQrCodeScannedUsers = false"
    />
    <preview-content-with-qr-code
      v-if="showPreview"
      :content-details="contentDetails"
      @closed="showPreview = false"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import global from 'src/mixins/global';
  import QrCodeScannedUsers from './QrCodeScannedUsers.vue';
  import { cdnUrl } from 'src/app-globals';
  import PreviewContentWithQrCode from './PreviewContentWithQrCode.vue';

  export default {
    name: 'QrCodeBanners',
    components: {
      'centre-spinner': spinner,
      'qr-code-scanned-users': QrCodeScannedUsers,
      'preview-content-with-qr-code': PreviewContentWithQrCode,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusUpdate (active) {
        if (active === true) return 'Active*';
        else return 'Inactive';
      },
      qrStatusUpdate (active) {
        if (active === true) return 'Active*';
        else return 'Removed';
      },
    },
    mixins: [global],
    data () {
      return {
        search: '',
        headers: [
          { text: 'Banner', align: 'start', value: 'name' },
          { text: 'QR Status', value: 'active' },
          { text: 'Campaign', value: 'name' },
          { text: 'File Name', value: 'content.original_file_name' },
          { text: 'Start Date', value: 'start_date' },
          { text: 'End Date', value: 'end_date' },
          { text: 'Status', value: 'active' },
          { text: 'Views', value: 'views' },
        ],
        loading: false,
        showQrCodeScannedUsers: false,
        bannerDetails: {},
        showPreview: false,
        contentDetails: {},
      };
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (vm.isPlanExpired === true) {
          vm.$router.push({ name: 'Dashboard' });
        } else {
          next();
        }
      });
    },
    computed: {
      qrCodeBanners () {
        return this.$store.getters['qrcodebanners/getQrCodeBanners'];
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      isPlanExpired () {
        if (Object.keys(this.organizationDetails).length > 0 && this.organizationDetails.subscription.subscription_status === 'expired') {
          return true;
        }
        return false;
      },
    },
    watch: {
      isPlanExpired (val) {
        if (val === true) {
          this.$router.push({ name: 'Logout' });
        }
      },
    },
    async mounted () {
     this.permissionCheck('get-qr-code-enabled-banners') && await this.getQrCodeBanners();
    },
    methods: {
      async getQrCodeBanners () {
        this.loading = true;
        await this.$store.dispatch('qrcodebanners/fetchQrCodeBanners').catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      getColor (active) {
        if (active === false) return '#C62828';
        else return '#2E7D32';
      },
      isContentImgOrGif (contentType) {
        if (contentType === 'image' || contentType === 'gif') {
          return true;
        }
        return false;
      },
      getFilePath (fileName) {
        return `${cdnUrl}/${fileName}`;
      },
      viewQrCodeScannedUsers (banner) {
        this.bannerDetails = banner;
        this.showQrCodeScannedUsers = true;
      },
      popUpImage (content) {
        this.contentDetails = content;
        this.showPreview = true;
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
